import { CSSProperties } from 'react';
import styled from 'styled-components';
import { SpacingType, spacingUtils } from 'utils/helpers/spacingUtils';

import { StateType } from './types';

interface StylesType extends CSSProperties {
  backgroundColorHover?: string;
  backgroundColorPressed?: string;
  colorHover?: string;
  colorPressed?: string;
  borderHover?: string;
  borderPressed?: string;
}

interface StyledButtonProps extends SpacingType {
  styles: StylesType;
  state: StateType;
  width?: string | number;
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};

  cursor: ${({ state }) =>
    ['inactive', 'loading'].includes(state) ? 'default' : 'pointer'};

  color: ${({ styles }) => styles.color};
  background-color: ${({ styles }) => styles.backgroundColor};
  height: ${({ styles }) => styles.height};
  padding: ${({ styles }) => styles.padding};
  border: ${({ styles }) => styles.border};

  &:hover {
    ${({ state, styles }) =>
      !['inactive', 'loading'].includes(state) &&
      `
		color: ${styles.colorHover};
    background-color: ${styles.backgroundColorHover};
    border: ${styles.borderHover};
		`}
  }

  &:active {
    ${({ state, styles }) =>
      !['inactive', 'loading'].includes(state) &&
      `
		color: ${styles.colorPressed};
		background-color: ${styles.backgroundColorPressed};
		border: ${styles.borderPressed};
	`}
  }

  ${({ width }) =>
    width &&
    `
		width: ${typeof width === 'number' ? `${width}px` : width};
	`}

  ${spacingUtils}
`;

export const ChildrenWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
`;

export const LoaderChildrenHidden = styled.div`
  visibility: hidden;
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
