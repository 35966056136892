import styled from 'styled-components';

interface SvgProps {
  shadow?: boolean;
  height: string;
  width: string;
  rotate?: string;
}

interface PathProps {
  variant?: 'primary' | 'secondary';
  color?: string;
}

export const Svg = styled.svg<SvgProps>``;

export const Path = styled.path<PathProps>``;
