import { ModeType } from 'interfaces';
import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
`;

interface CheckboxInputProps {
  mode?: ModeType;
  width: number;
  height: number;
  disabled?: boolean;
}

export const CheckboxInput = styled.input<CheckboxInputProps>`
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  appearance: none;
  position: relative;
  border-radius: ${({ theme }) => theme.styles.borderRadius};
  cursor: pointer;
  outline: none;
  background-color: ${({ theme }) => theme.colors.grey[0]};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  transition: color 0.2s ease;

  &:checked {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10.1214 15.5991L18.3294 7.29399C18.523 7.098 18.7531 7 19.0194 7C19.2857 7 19.5158 7.098 19.7095 7.29399C19.9032 7.48998 20 7.72272 20 7.9922C20 8.26169 19.9032 8.49443 19.7095 8.69042L10.7993 17.706C10.6056 17.902 10.3796 18 10.1214 18C9.86312 18 9.63714 17.902 9.44344 17.706L5.27893 13.4922C5.08523 13.2962 4.99241 13.0635 5.00048 12.794C5.00856 12.5245 5.10944 12.2918 5.30314 12.0958C5.49684 11.8998 5.72685 11.8018 5.99319 11.8018C6.25952 11.8018 6.48954 11.8998 6.68324 12.0958L10.1214 15.5991Z" fill="%23${({
        theme,
      }) => theme.colors.primary[500].substring(1)}"/></svg>');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &:not(:checked)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  ${({ disabled, theme }) =>
    disabled
      ? `
		cursor: not-allowed;
		background-color: ${theme.colors.grey[200]};
			`
      : `
		&:not(:checked):hover {
			border: 2px solid ${theme.colors.grey[400]};
		}

		&:not(:checked):hover::after {
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10.1214 15.5991L18.3294 7.29399C18.523 7.098 18.7531 7 19.0194 7C19.2857 7 19.5158 7.098 19.7095 7.29399C19.9032 7.48998 20 7.72272 20 7.9922C20 8.26169 19.9032 8.49443 19.7095 8.69042L10.7993 17.706C10.6056 17.902 10.3796 18 10.1214 18C9.86312 18 9.63714 17.902 9.44344 17.706L5.27893 13.4922C5.08523 13.2962 4.99241 13.0635 5.00048 12.794C5.00856 12.5245 5.10944 12.2918 5.30314 12.0958C5.49684 11.8998 5.72685 11.8018 5.99319 11.8018C6.25952 11.8018 6.48954 11.8998 6.68324 12.0958L10.1214 15.5991Z" fill="%23${theme.colors.grey[400].substring(
        1
      )}"/></svg>');
		}

		&:not(:checked):active {
			border: 1px solid ${theme.colors.grey[500]};
		}

		&:not(:checked):active::after {
			color: ${theme.colors.grey[500]};
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10.1214 15.5991L18.3294 7.29399C18.523 7.098 18.7531 7 19.0194 7C19.2857 7 19.5158 7.098 19.7095 7.29399C19.9032 7.48998 20 7.72272 20 7.9922C20 8.26169 19.9032 8.49443 19.7095 8.69042L10.7993 17.706C10.6056 17.902 10.3796 18 10.1214 18C9.86312 18 9.63714 17.902 9.44344 17.706L5.27893 13.4922C5.08523 13.2962 4.99241 13.0635 5.00048 12.794C5.00856 12.5245 5.10944 12.2918 5.30314 12.0958C5.49684 11.8998 5.72685 11.8018 5.99319 11.8018C6.25952 11.8018 6.48954 11.8998 6.68324 12.0958L10.1214 15.5991Z" fill="%23${theme.colors.grey[500].substring(
        1
      )}"/></svg>');
		}
	`};

  ${({ mode, theme }) =>
    mode === 'dark' &&
    `
		background-color: transparent;
		border-color: ${theme.colors.grey[400]};
		&:checked {
			border-color: ${theme.colors.grey[0]};
  	}
	`}
`;

export const CheckboxLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1]};
`;

interface CheckboxLabel {
  fontSize: string;
  fontWeight: string;
  color?: string;
}

export const CheckboxLabel = styled.label<CheckboxLabel>`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) =>
    color ? color : ({ theme }) => theme.colors.grey[1000]};
`;
