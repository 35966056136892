import { Path, Svg } from './styles';

interface CheckIconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const CheckIcon = ({
  height = '24',
  width = '24',
  color = '#424249',
}: CheckIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1167_5887)">
      <Path
        d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7.00003L19.5902 5.59003L9.00016 16.17Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1167_5887">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
