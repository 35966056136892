import { ModeType } from 'interfaces';
import React from 'react';
import { useTheme } from 'styled-components';

import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxLabelsWrapper,
  CheckboxWrapper,
} from './styles';
import { CheckboxLabelType, CheckboxSizeType } from './types';

type LabelType = {
  text: string;
  type?: CheckboxLabelType;
};

interface CheckboxProps {
  mode?: ModeType;
  name?: string;
  size?: CheckboxSizeType;
  labels?: LabelType[];
  checked?: boolean;
  disabled?: boolean;
  color?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
}

export default function Checkbox({
  mode,
  name,
  size = 'standard',
  labels,
  checked,
  disabled,
  color,
  onClick,
  onChange,
  ...rest
}: CheckboxProps) {
  const theme = useTheme();
  const sizeStylesMapper = {
    small: {
      width: 20,
      height: 20,
    },
    standard: {
      width: 24,
      height: 24,
    },
  };

  const labelStylesMapper = {
    option: {
      fontSize: theme.fonts.size.base,
      fontWeight: theme.fonts.weight.normal,
    },
    title: {
      fontSize: theme.fonts.size.base,
      fontWeight: theme.fonts.weight.medium,
    },
    description: {
      fontSize: theme.fonts.size.sm,
      fontWeight: theme.fonts.weight.normal,
    },
  };

  const sizeStyles = sizeStylesMapper[size];

  return (
    <CheckboxWrapper>
      <CheckboxInput
        id={name}
        mode={mode}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
        {...sizeStyles}
        {...rest}
      />
      {labels?.length && (
        <CheckboxLabelsWrapper>
          {labels.map((label, index) => {
            const labelStyles = labelStylesMapper[label.type || 'option'];
            return (
              <CheckboxLabel
                key={index}
                {...labelStyles}
                color={color}
                htmlFor={name}
              >
                {label.text}
              </CheckboxLabel>
            );
          })}
        </CheckboxLabelsWrapper>
      )}
    </CheckboxWrapper>
  );
}
