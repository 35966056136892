import React from 'react';
import { DocumentIcon, PlusIcon } from 'stories/Icons';
import { DefaultTheme, useTheme } from 'styled-components';
import Loader from 'ui/Loader';
import { SpacingType } from 'utils/helpers/spacingUtils';

import {
  ChildrenWrapper,
  LoaderChildrenHidden,
  LoaderWrapper,
  StyledButton,
} from './styles';
import { GlyphName, KindType, SizeType, StateType, VariantType } from './types';

interface ButtonProps extends SpacingType {
  children?: React.ReactNode;
  variant?: VariantType;
  kind?: KindType;
  size?: SizeType;
  state?: StateType;
  glyphName?: GlyphName;
  type?: 'button' | 'submit';
  width?: string | number;
  onClick?: () => void;
}

interface GetGlyphParams {
  glyphName?: GlyphName;
  color?: string;
  size?: string;
}

interface GetStylesParams {
  variant: VariantType;
  kind: KindType;
  size: SizeType;
  state: StateType;
  glyphName?: GlyphName;
  theme: DefaultTheme;
}

const getStyles = ({
  variant,
  kind,
  size,
  state,
  glyphName,
  theme,
}: GetStylesParams) => {
  const { primary, green, red, grey } = theme.colors;

  const kindColorMap = {
    neutral: {
      default: primary[500],
      hover: primary[450],
      pressed: primary[600],
    },
    success: {
      default: green[500],
      hover: green[450],
      pressed: green[600],
    },
    destructive: {
      default: red[500],
      hover: red[450],
      pressed: red[600],
    },
  };

  const heightMap = {
    standard: '40px',
    large: '60px',
    small: '32px',
  };

  const kindColor = kindColorMap[kind] || kindColorMap.neutral;

  const color = ['inactive'].includes(state)
    ? {
        default: grey[300],
        hover: grey[300],
        pressed: grey[300],
      }
    : kindColor;

  const height = heightMap[size] || heightMap.standard;

  const padding =
    variant === 'glyph'
      ? theme.spacing[4]
      : glyphName
      ? `${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[3]}`
      : `${theme.spacing[4]} ${theme.spacing[6]}`;

  const commonStyle = {
    padding,
    height,
  };

  const variantStyles = {
    primary: {
      ...commonStyle,
      color: grey[0],
      colorHover: grey[0],
      backgroundColor: color.default,
      backgroundColorHover: color.hover,
      backgroundColorPressed: color.pressed,
      border: 'none',
      borderHover: 'none',
      borderPressed: 'none',
    },
    secondary: {
      ...commonStyle,
      color: color.default,
      backgroundColor: 'transparent',
      border: `1px solid ${color.default}`,
      borderHover: `1px solid ${color.hover}`,
      borderPressed: `1px solid ${color.pressed}`,
    },
    tertiary: {
      ...commonStyle,
      color: color.default,
      colorHover: color.hover,
      colorPressed: color.pressed,
      backgroundColor: 'transparent',
      backgroundColorHover: 'rgba(0, 0, 0, 0.10)',
      backgroundColorPressed: 'rgba(0, 0, 0, 0.20)',
    },
    glyph: {
      ...commonStyle,
      color: color.default,
      colorHover: color.hover,
      colorPressed: color.pressed,
      backgroundColor: 'transparent',
      backgroundColorHover: 'rgba(0, 0, 0, 0.10)',
      backgroundColorPressed: 'rgba(0, 0, 0, 0.20)',
    },
  };
  if (theme.client === 'solyco') {
    variantStyles.primary = {
      ...commonStyle,
      color: grey[0],
      colorHover: color.default,
      backgroundColor: color.default,
      backgroundColorHover: 'transparent',
      backgroundColorPressed: 'transparent',
      border: `1px solid ${color.default}`,
      borderHover: `1px solid ${color.default}`,
      borderPressed: `1px solid ${color.default}`,
    };
    return variantStyles[variant];
  }
  return variantStyles[variant];
};

const getGlyph = ({ glyphName, color, size }: GetGlyphParams) => {
  switch (glyphName) {
    case 'plus': {
      return <PlusIcon fillColor={color} width={size} height={size} />;
    }
    case 'pdf': {
      return (
        <DocumentIcon
          fillColor={color}
          width={size}
          height={size}
          variant="pdf"
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default function Button({
  children,
  variant = 'primary',
  kind = 'neutral',
  size = 'standard',
  state = 'default',
  type = 'button',
  glyphName,
  width,
  onClick,
  ...rest
}: ButtonProps) {
  const theme = useTheme();

  const styles = getStyles({
    variant,
    kind,
    size,
    state,
    glyphName,
    theme,
  });

  const isDisabled = ['inactive', 'loading'].includes(state);

  const glyphSizeMapper = {
    small: '24',
    standard: '24',
    large: variant === 'glyph' ? '32' : '24',
  };

  const glyphSize = glyphSizeMapper[size];

  const ChildrenContent = (
    <>
      {glyphName &&
        getGlyph({ glyphName, color: styles.color, size: glyphSize })}
      {children}
    </>
  );

  return (
    <StyledButton
      styles={styles}
      state={state}
      type={type}
      disabled={isDisabled}
      width={width}
      onClick={isDisabled ? undefined : onClick}
      {...rest}
    >
      {state === 'loading' ? (
        <>
          <ChildrenWrapper>
            <LoaderChildrenHidden>{ChildrenContent}</LoaderChildrenHidden>
            <LoaderWrapper>
              <Loader fromColor={styles.color} toColor={styles.color} />
            </LoaderWrapper>
          </ChildrenWrapper>
        </>
      ) : variant === 'glyph' ? (
        getGlyph({
          glyphName: glyphName || 'plus',
          color: styles.color,
          size: glyphSize,
        })
      ) : (
        <ChildrenWrapper>{ChildrenContent}</ChildrenWrapper>
      )}
    </StyledButton>
  );
}
